/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-earmark-code-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M9.293 0H4a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V4.707A1 1 0 0013.707 4L10 .293A1 1 0 009.293 0M9.5 3.5v-2l3 3h-2a1 1 0 01-1-1M6.646 7.646a.5.5 0 11.708.708L5.707 10l1.647 1.646a.5.5 0 01-.708.708l-2-2a.5.5 0 010-.708zm2.708 0l2 2a.5.5 0 010 .708l-2 2a.5.5 0 01-.708-.708L10.293 10 8.646 8.354a.5.5 0 11.708-.708"/>',
    },
});
